'format es6';

import { slideToggle } from './utils/slide';
import gsap, { TweenMax } from 'gsap';

let isMobile;
let tabModule;
const tabContainer = document.querySelector('[data-tab-content]');

function animTab(control, content) {
	control.removeEventListener('click', activeTab)
	const containerHeight = tabContainer.clientHeight;
	tabContainer.style.height = 'auto';

	const newHeight = content.clientHeight;

	TweenMax.fromTo(tabContainer, 0.8, { 
		height: `${containerHeight}px` 
	}, { 
		height: `${newHeight}px`, 
		onComplete:() => {
			TweenMax.to(content, 0.3, { opacity: 1, position: 'relative'});
		}
	});

	TweenMax.delayedCall(1, () => {
		TweenMax.set(tabContainer, { clearProps: 'all' });
	});
}

function desactivateActive() {
	const activeParent = document.querySelector('[data-tab-parent].active');
	if (!activeParent) return;
	const parentId = activeParent.getAttribute('data-tab-parent');
	const activeContent = tabContainer.querySelector(`[data-tab-children="${parentId}"]`);

	activeContent.classList.remove('active');
	activeParent.classList.remove('active');

	TweenMax.to(activeContent, 0.3, { 
		opacity: 0, 
		onComplete:() => {
			TweenMax.to(activeContent, 0.3, { position: 'absolute'});
		}
	});
	activeParent.classList.remove('active');
	activeParent.addEventListener('click', activeTab)
}

function activeTab(e, node) {
	desactivateActive();
	const control = e ? e.currentTarget : node;
	const id = control.getAttribute('data-tab-parent');
	const target = tabContainer.querySelector(`[data-tab-children="${id}"]`);
	control.classList.add('active');
	
	animTab(control, target);
}

function activeCollapser(e, node) {
	if (e) e.preventDefault();
	const clicked = e ? e.currentTarget : node;
	const rootId = clicked.getAttribute('data-tab-title');
	const target = document.querySelector(`[data-tab-content="${rootId}"]`);

	clicked.classList.toggle('open');
	slideToggle(target);
}

function clearProps(elemArray) {
	elemArray.forEach((elem) => {
		TweenMax.to(elem, 0, {clearProps: "all"});
	});
}

function resize() {
	if (window.matchMedia('(max-width: 767px)').matches && !isMobile) {
		isMobile = true;

		const children = Array.from(tabModule.querySelectorAll('[data-tab-children]'));
		clearProps(children);

		const allTabs = Array.from(tabModule.querySelectorAll('[data-tab-title]'));
		allTabs.forEach((curr) => curr.addEventListener('click', activeCollapser));
		activeCollapser(null, allTabs[0]);
	} else if (window.matchMedia('(min-width: 767px)').matches && isMobile) {
		isMobile = false;

		const children = Array.from(tabModule.querySelectorAll('[data-tab-content]'));
		clearProps(children);

		const allTabs = Array.from(tabModule.querySelectorAll('[data-tab-parent]'));
		allTabs.forEach((curr) => curr.addEventListener('click', activeTab));
		activeTab(null, allTabs[0]);
	}
}

export default {
	init() {
		tabModule = document.querySelector('[data-tab]');
		if(!tabModule) return;

		// set isMobile au load de la page
		if (window.matchMedia('(max-width: 767px)').matches) {
			isMobile = false;
		} else {
			isMobile = true;
		}

		window.addEventListener('resize', resize);
		resize();
	},
};
