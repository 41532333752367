'format es6';
'use strict';

import './utils/polyfills';

import $ from 'jquery';
import { docReady } from './utils/docReady';
import { isEdge } from './utils/browserDetect';
import Collapser from './Collapser';
import Tabs from './Tabs';
import objectFitVideos from 'object-fit-videos';
import Newsletter from './Newsletter';
import PdfContainer from './PdfContainer';


docReady.then(() => {
	if (isEdge([12, 13, 14])) {
		$('img')
			.removeAttr('srcset')
			.removeAttr('sizes');
	}
	objectFitVideos();

	const menu = document.querySelector('[data-main-menu]');
	if (menu) {
		const btnMenu = menu.querySelector('[data-menu-button]');
		const btnCloseMenu = menu.querySelector('[data-menu-close-button]');
		const topMenu = menu.querySelector('[data-top-menu]');

		const toggleMenu = () => {
			topMenu.classList.toggle('opened');
		};

		btnMenu.addEventListener('click', toggleMenu);
		btnCloseMenu.addEventListener('click', toggleMenu);
	}

	Collapser.init();
	Tabs.init();
	Newsletter.init();
	PdfContainer.init();
});

