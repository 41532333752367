import ns from 'ns';
import reqwest from 'reqwest';

import { slideUp, slideDown } from './utils/slide';

function animOut(form, message) {
	const formMessage = form.parentNode.querySelector('[data-form-message]');

	slideUp(form);
	formMessage.innerHTML = message;
	setTimeout(() => {
		slideDown(formMessage);
	}, 400);
}

function submitNewsletter(e) {
	e.preventDefault();
	const submittedForm = e.currentTarget;

	const formData = new FormData();
	const inputs = submittedForm.querySelectorAll('input');
	inputs.forEach((field) => {
		formData.append(field.name, field.value);
	});
	
	reqwest({
		url: submittedForm.getAttribute('action'),
		data: formData,
		processData: !(formData instanceof FormData),
		method: 'POST',
		type: 'POST',
		success: (resp) => {
			animOut(submittedForm, ns.newsletterSuccess);
		},
		error: (resp) => {
			animOut(submittedForm, ns.newsletterError);
		},
	});
	
}

export default {
	init() {
		const formNewsletter = document.querySelectorAll('#newsletter');
		formNewsletter.forEach(form => {
			form.addEventListener('submit', submitNewsletter);
		});
	},
};
