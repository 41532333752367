'format es6';
'use strict';

import Promise from 'bluebird';
try{require('source-map-'+'support').install();}catch(e){}

const name = 'peripharm';

const ns = window[name] = (window[name] || {});


export default ns;

