'format es6';

import $ from 'jquery';

function Collapser(i, node, closeOpenPanels) {
	const rootNode = $(node);
	const rootId = rootNode.data('expendable');
	const control = $(`[data-expendable-control="${rootId}"]`, rootNode);
	const target = $(`[data-expendable-target="${rootId}"]`, rootNode);

	function isElementInViewport(el) {

		//special bonus for those using jQuery
		if (typeof jQuery === "function" && el instanceof jQuery) {
			el = el[0];
		}

		var rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
			rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
		);
	}

	function toggleCollapse(e) {
		e.preventDefault();

		control.off('click.tb');

		// Fermeture du panneau déjà ouvert s'il y a lieu
		if (!(control.hasClass('open'))) {
			closeOpenPanels();
		}

		control.toggleClass('open');

		target.slideToggle(300, () => {
			target.toggleClass('open');

			control.on('click.tb', toggleCollapse);

			// ScrollTo si nécessaire
			if (!isElementInViewport(control)) {
				$('html,body').animate({
					scrollTop: control.offset().top
				}, 100);
			}
		});
	}

	control.on('click.tb', toggleCollapse);
}


export default {
	init() {
		const allCollapsables = $('[data-expendable]');
		const allControls = $('[data-expendable-control]');
		const allTargets = $('[data-expendable-target]');

		function closeOpenPanels() {
			allControls.each((index, node) => {
				$(node).removeClass('open');
			});

			allTargets.each((index, node) => {
				const selected = $(node);
				if (selected.hasClass('open')) {
					selected.slideUp(300, () => {
						selected.removeClass('open');
					});
				}
			});
		}

		allCollapsables.each((i, node) => {
			Collapser(i, node, closeOpenPanels);
		});
	},
};